import { Button, Stack, Text } from "@chakra-ui/react";
import { Armchair, Library, Utensils } from "lucide-react";
import { useRouter } from "next/router";
import { memo } from "react";

import { AuthenticatedMainLayout } from "../AuthenticatedMainLayout";

import { NavigationHeader } from "src/components/NavigationHeader";
import { pagesPath } from "src/utils/$path";

export const HomeScreen = memo(() => {
  const router = useRouter();
  return (
    <AuthenticatedMainLayout>
      <NavigationHeader>ホーム</NavigationHeader>
      <Stack px={8} spacing={8}>
        <Button
          leftIcon={<Armchair size={22} color="#A19B6E" />}
          variant="outline"
          w="100%"
          h={90}
          boxShadow="sm"
          onClick={() => router.push(pagesPath.seat_management.$url())}
        >
          <Text fontWeight="semibold">ショップカードの配置</Text>
        </Button>
        <Button
          leftIcon={<Utensils size={22} color="#A19B6E" />}
          variant="outline"
          w="100%"
          h={90}
          boxShadow="sm"
          onClick={() => router.push(pagesPath.active_visits.$url())}
        >
          <Text fontWeight="semibold">来店中の顧客</Text>
        </Button>
        <Button
          leftIcon={<Library size={22} color="#A19B6E" />}
          variant="outline"
          w="100%"
          h={90}
          boxShadow="sm"
          onClick={() => router.push(pagesPath.histories.$url())}
        >
          <Text fontWeight="semibold">来店履歴</Text>
        </Button>
      </Stack>
    </AuthenticatedMainLayout>
  );
});
