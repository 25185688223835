import { HStack, IconButton } from "@chakra-ui/react";
import { Text } from "@repo/ui/text";
import { ChevronLeft } from "lucide-react";
import { useRouter } from "next/router";
import { ReactNode } from "react";

export const NavigationHeader = ({
  showBackButton,
  children,
}: {
  showBackButton?: boolean;
  children?: ReactNode;
}) => {
  const router = useRouter();
  return (
    <HStack spacing={2} px={showBackButton ? 0 : 4} h="48px" align="center">
      {showBackButton && (
        <IconButton
          aria-label="戻る"
          icon={<ChevronLeft size="32px" />}
          variant="ghost"
          onClick={() => router.back()}
        />
      )}
      <Text textStyle="Heading/Large" as="h2">
        {children}
      </Text>
    </HStack>
  );
};
